exports.components = {
  "component---src-layouts-blog-post-js": () => import("./../../../src/layouts/blog-post.js" /* webpackChunkName: "component---src-layouts-blog-post-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-clientes-tsx": () => import("./../../../src/pages/clientes.tsx" /* webpackChunkName: "component---src-pages-clientes-tsx" */),
  "component---src-pages-common-footer-tsx": () => import("./../../../src/pages/common/footer.tsx" /* webpackChunkName: "component---src-pages-common-footer-tsx" */),
  "component---src-pages-common-header-tsx": () => import("./../../../src/pages/common/header.tsx" /* webpackChunkName: "component---src-pages-common-header-tsx" */),
  "component---src-pages-common-heroshot-tsx": () => import("./../../../src/pages/common/heroshot.tsx" /* webpackChunkName: "component---src-pages-common-heroshot-tsx" */),
  "component---src-pages-common-instagram-photo-tsx": () => import("./../../../src/pages/common/instagram/Photo.tsx" /* webpackChunkName: "component---src-pages-common-instagram-photo-tsx" */),
  "component---src-pages-common-navbar-tsx": () => import("./../../../src/pages/common/navbar.tsx" /* webpackChunkName: "component---src-pages-common-navbar-tsx" */),
  "component---src-pages-homepage-tagline-tsx": () => import("./../../../src/pages/homepage/tagline.tsx" /* webpackChunkName: "component---src-pages-homepage-tagline-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portafolio-tsx": () => import("./../../../src/pages/portafolio.tsx" /* webpackChunkName: "component---src-pages-portafolio-tsx" */)
}

